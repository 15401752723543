:root {
    --primary-color: #3f54cc;
    --text-default: #202025;
    --secondary-color: #ffffff;
    --disabled-color: #75767d;
    --shadow-color: rgba(0, 0, 0, 0.08);
    --border-color: #f5f5f7;
    --modal-footer-color: #f9f9fc;
    --border-disabled-color: #e0e0e5;
    --success-light-color: #d5ffe8;
    --font-size-base: 16px;
    --line-height-base: calc(var(--font-size-base) * 1.5);
    --margin: var(--font-size-base);
    --padding: var(--font-size-base);
}

body {
    margin: 0;
    font-family:
        'Inter',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

header {
    color: var(--primary-color);
    background-color: var(--secondary-color);
    padding: calc(var(--padding) * 0.75) calc(var(--padding) * 1.25);
    display: flex;
}

.header__container {
    padding-left: 0px;
    padding-right: 0px;
    max-width: unset;
}

.header__row {
    align-items: center;
}

.header__col {
    padding-left: 0px;
    padding-right: 0px;
}

.header__col:last-child {
    text-align: right;
}

#body {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    color: var(--text-default);
    column-gap: var(--margin);
}

.body__background {
    position: absolute;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: calc(var(--padding) * 20);
    top: calc(var(--padding) * 4);
}

.button__rounded,
.form__buttons__button {
    border-radius: calc(var(--padding) * 3);
    padding-left: var(--padding);
    padding-right: var(--padding);
    font-weight: 600;
}

.container__page {
    max-width: calc(var(--font-size-base) * 35);
    padding-left: 0px;
    padding-right: 0px;
    margin-top: calc(var(--margin) * 6);
}

.container__page--full {
    max-width: 90%;
}

.panel {
    border-radius: var(--padding);
    box-shadow: 0 calc(var(--padding) * 0.5) calc(var(--padding) * 1.5)
        var(--shadow-color);
    border: 1px solid var(--border-color);
    padding: var(--padding);
    margin: var(--margin);
}

.panel__body__title,
.form__section__title {
    font-size: calc(var(--font-size-base) * 1.25);
    line-height: calc(var(--font-size-base) * 1.75);
    font-weight: 600;
}

.panel__body__title--empty {
    display: none;
}

.panel__body__title__col__center {
    text-align: center;
}

.panel__footer {
    margin: calc(var(--margin) * -1);
    margin-top: var(--margin);
}

.form__row {
    padding-bottom: var(--padding);
}

.form__row__content__label {
    font-size: calc(var(--font-size-base) * 0.85);
    font-weight: 500;
    color: var(--text-default);
    line-height: var(--font-size-base);
}

.form__section__title {
    display: flex;
    justify-content: center;
    margin-bottom: var(--padding);
}

.form__buttons {
    padding: 0 var(--padding);
}

.form__page--active {
    display: block;
}

.form__page--inactive {
    display: none;
}

.react-date-picker__wrapper {
    height: calc(var(--padding) * 2.25);
    padding: 0 calc(var(--padding) * 0.5);
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    border-radius: var(--bs-border-radius);
}

.navbar {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    display: flex;
    padding: 0;
}

.navbar__item {
    margin-right: var(--margin);
    list-style: none;
}

.navbar__item__link {
    color: var(--primary-color);
    text-decoration: none;
    &:hover {
        color: var(--primary-color);
        text-decoration: underline;
    }
}

#go-home-logo {
    height: 42px;
    cursor: pointer;
}

.centered {
    text-align: center;
}

.highlighted {
    font-weight: bolder;
}

.inactive {
    display: none;
}

.modal__body {
    display: block;
    text-align: -webkit-center;
}

.modal__header__title {
    gap: calc(var(--padding) * 0.5);
}

.modal__header__title__back {
    color: var(--disabled-color);
    cursor: pointer;
}

.modal__header__title__back--hidden {
    display: none;
}

.modal__body__title {
    display: flex;
    text-align: left;
    line-height: calc(var(--font-size-base) * 1.5);
    flex-direction: column;
    gap: calc(var(--padding) * 0.25);
    margin-bottom: calc(var(--margin) * 1.5);
}

.modal__body__title__title {
    font-size: calc(var(--font-size-base) * 1.25);
    font-weight: 600;
}

.modal__body__title__subtitle {
    font-weight: 400;
    color: var(--disabled-color);
}

.modal__body__section__title {
    padding-bottom: calc(var(--padding) * 0.25);
}

.divider {
    display: flex;
    align-items: center;
    gap: calc(var(--padding) * 0.5);
    color: var(--disabled-color);
    font-size: calc(var(--font-size-base) * 0.875);
    font-weight: 400;
    line-height: calc(var(--font-size-base) * 1.25);
    margin: var(--margin) 0px;
}

.divider__line {
    background-color: var(--border-color);
    height: 1px;
    width: 100%;
}

.openid4vc__qr__copy {
    font-size: calc(var(--font-size-base) * 0.75);
}

.openid4vc__qr {
    display: flex;
    gap: var(--padding);
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.openid4vc__qr__title {
    margin-bottom: calc(var(--padding) * 0.75);
}

.openid4vc__qr__title--hidden {
    display: none;
}

.openid4vc__qr__description {
    color: var(--disabled-color);
}

.openid4vc__info {
    padding: 0px;
}

.login__button {
    font-weight: 500;
    font-size: calc(var(--font-size-base) * 0.885);
    line-height: calc(var(--font-size-base) * 0.885);
    padding: calc(var(--font-size-base) * 0.565)
        calc(var(--font-size-base) * 1.25);
}

.modal-dialog {
    margin-top: calc(var(--margin) * 10);
    width: calc(var(--font-size-base) * 30);
}

.modal-header {
    padding: calc(var(--padding) * 1.5) calc(var(--padding) * 2);
    gap: calc(var(--padding) * 0.5);
}

.modal-title {
    font-size: calc(var(--font-size-base) * 1.25);
    line-height: calc(var(--font-size-base) * 1.5);
    gap: calc(var(--padding) * 0.5);
    display: flex;
    font-weight: 600;
    width: 100%;
    align-items: center;
}

.modal-header .btn-close {
    margin: 0px;
    padding: 0px;
}

.modal-body {
    padding: calc(var(--padding) * 2);
}

.modal-footer,
.card-footer {
    display: flex;
    justify-content: center;
    background-color: var(--modal-footer-color);
    border-top: 1px solid var(--border-color);
    padding: calc(var(--padding) * 1.25) calc(var(--padding) * 2);
    gap: calc(var(--padding) * 0.25);
}

.modal__footer--hidden,
.panel__footer--empty {
    display: none;
}

.modal__footer__text,
.panel__footer__text {
    color: var(--disabled-color);
    font-size: var(--font-size-base);
    line-height: calc(var(--font-size-base) * 1.5);
    margin: 0px;
}

.modal__footer__link,
.panel__footer__link {
    color: var(--primary-color);
    font-size: var(--font-size-base);
    line-height: calc(var(--font-size-base) * 1.5);
    font-weight: 600;
    margin: 0px;
    cursor: pointer;
}

.modal__body .openid4vc__info__button {
    font-size: var(--font-size-base);
    line-height: calc(var(--font-size-base) * 1.5);
    padding: var(--font-size-base) calc(var(--font-size-base) * 2);
    width: 100%;
}

.google__button {
    display: flex;
    justify-content: center;
    gap: calc(var(--padding) * 0.5);
    color: var(--text-default);
}

.modal__body .google__button {
    font-size: var(--font-size-base);
    line-height: calc(var(--font-size-base) * 1.5);
    padding: var(--font-size-base) calc(var(--font-size-base) * 2);
    width: 100%;
}

.todo {
    display: flex;
    flex-direction: column;
    border: 1px solid blue;
    padding: 4px;
    text-align: left;
    color: blue;
    font-size: calc(var(--font-size-base) * 0.75);
    line-height: calc(var(--font-size-base) * 0.75);
    gap: 4px;
}

.todo:not(:last-child) {
    margin-bottom: 4px;
}

.todo--hidden {
    display: none;
}

.todo__title {
    font-weight: 600;
}

.todo__description {
    padding-left: 8px;
    padding-right: 8px;
}

.todo__who {
    padding-top: 8px;
}

.todo__hide {
    font-size: calc(var(--font-size-base) * 0.75);
    line-height: calc(var(--font-size-base) * 0.75);
    width: 50px;
    margin-left: auto;
    margin-top: -25px;
}

.todo__who::before {
    content: 'Assigned: ';
    font-weight: 600;
}

.bg-success-light {
    background-color: var(--success-light-color);
}

.session-info__value--scroll {
    overflow-y: scroll;
    max-width: 600px;
    border: 0.5px solid lightgray;
    padding: 5px;
    white-space: pre-wrap;
}
