.flight__row {
    padding: var(--padding) 0;
    align-items: center;
}

.flight__row:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.flight__row:last-child {
    padding-bottom: 0px;
}

.flight__row__col__left {
    padding-left: 0px;
    padding-right: 0px;
}

.flight__row__col__center {
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) * 0.25);
}

.flight__row__col__right {
    padding: 0;
    text-align: right;
}

.flight__row__companylogo {
    height: calc(var(--padding) * 3);
}

.flight__row__description {
    display: flex;
    gap: var(--padding);
}

.flight__row__description__airport__origin {
    text-align: right;
}

.flight__row__description__airport__code {
    color: var(--disabled-color);
    font-size: calc(var(--font-size-base) * 0.85);
    line-height: calc(var(--font-size-base) * 1.5);
    font-weight: 500;
}

.flight__row__description__airport__time {
    color: var(--text-default);
    font-size: var(--font-size-base);
    line-height: var(--font-size-base);
    font-weight: 600;
}

.flight__row__description__icon {
    display: flex;
}

.flight__row__duration {
    font-size: calc(var(--font-size-base) * 0.75);
    line-height: calc(var(--font-size-base) * 0.85);
    font-weight: 400;
    display: flex;
    justify-content: center;
    gap: calc(var(--padding) / 2);
}

.flight__row__duration__value {
    color: var(--disabled-color);
}

.flight__row__duration__type {
    color: var(--green-base, #399d66);
    text-transform: capitalize;
}

.flight__row__selection {
    white-space: nowrap;
    width: calc(var(--font-size-base) * 4.5);
    font-weight: 600;
}

.flight__confirmation__icon {
    width: calc(var(--padding) * 4);
    height: calc(var(--padding) * 4);
    padding: calc(var(--padding) * 0.3);
    color: var(--primary-color);
}

.flight__confirmation__title {
    display: flex;
    margin: var(--margin) 0px;
    gap: calc(var(--padding) * 0.5);
    align-items: center;
}

.flight__confirmation__title__back {
    width: calc(var(--font-size-base) * 1.5);
    height: calc(var(--font-size-base) * 1.5);
}

.flight__confirmation__title__content {
    font-size: calc(var(--font-size-base) * 1.5);
    font-weight: 600;
    line-height: calc(var(--font-size-base) * 1.75);
    width: 100%;
}

.flight__confirmation__info {
    background-color: rgba(62.88, 83.84, 204, 0.04);
    border-radius: calc(var(--padding) * 0.5);
    padding: calc(var(--padding) * 0.75) var(--padding);
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) * 0.5);
}

.flight__confirmation__info__row {
    display: flex;
    align-items: center;
    gap: calc(var(--padding) * 0.5);
    text-align: left;
    padding: calc(var(--padding) * 0.25) 0px;
}

.flight__confirmation__info__row__label {
    font-size: calc(var(--font-size-base) * 0.875);
    font-weight: 400;
    line-height: calc(var(--font-size-base) * 1.25);
    width: calc(var(--font-size-base) * 5.5);
}

.flight__confirmation__info__row__value {
    font-size: var(--font-size-base);
    font-weight: 600;
    line-height: calc(var(--font-size-base) * 1.5);
    display: inline-flex;
    gap: calc(var(--padding) * 0.5);
}

.flight__confirmation__info__row__value__icon {
    width: calc(var(--font-size-base) * 1.5);
    height: calc(var(--font-size-base) * 1.5);
}

.flight__confirmation__onlinecheckin,
.flight__confirmation__vcs {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    padding-top: var(--padding);
    margin-top: var(--margin);
}

.flight__confirmation__vcs__info {
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) * 0.25);
}

.flight__confirmation__onlinecheckin__title,
.flight__confirmation__vcs__info__title {
    font-size: calc(var(--font-size-base) * 1.25);
    line-height: calc(var(--font-size-base) * 1.5);
    font-weight: 600;
}

.flight__confirmation__onlinecheckin__button,
.flight__confirmation__vcs__button {
    font-size: var(--font-size-base);
    line-height: calc(var(--font-size-base) * 1.5);
    padding: var(--font-size-base) calc(var(--font-size-base) * 2);
    width: 100%;
}

.flight__confirmation__vcs__row {
    text-align: left;
    padding: 0px;
}

.flight__confirmation__vcs__row__info {
    padding: 0px;
}

.flight__confirmation__vcs___row__info__btn {
    margin-top: calc(var(--padding) * 0.25);
}

.flight__confirmation__vcs__row__spinner {
    margin-top: calc(var(--padding) * 0.25);
    text-align: center;
}

.flight__confirmation__onlinecheckin__form {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    text-align: left;
}

.flight__confirmation__onlinecheckin__form__radioquestion {
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) * 0.75);
}

.flight__confirmation__onlinecheckin__form__radioquestion__radio {
    margin: 0px;
}

.flight__confirmation__onlinecheckin__services {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: 600;
    gap: var(--padding);
}

.flight__confirmation__onlinecheckin__services__service {
    display: flex;
    flex-direction: row;
    gap: var(--padding);
    padding-bottom: var(--padding);
    align-items: flex-start;
}

.flight__confirmation__onlinecheckin__services__service:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.flight__confirmation__onlinecheckin__services__service__icon {
    padding: var(--padding);
    background-color: var(--modal-footer-color);
    border-radius: calc(var(--padding) * 0.5);
}

.flight__confirmation__onlinecheckin__services__service__icon__image {
    width: calc(var(--font-size-base) * 2);
    height: calc(var(--font-size-base) * 2);
}

.flight__confirmation__onlinecheckin__services__service__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) * 0.5);
}

.flight__confirmation__onlinecheckin__services__service__body__value {
    display: flex;
    flex-direction: row;
    gap: calc(var(--padding) * 0.5);
}

.flight__confirmation__onlinecheckin__services__service__body__value__tag {
    font-size: calc(var(--font-size-base) * 0.75);
    line-height: calc(var(--font-size-base));
    font-weight: 500;
    padding: calc(var(--padding) * 0.25) calc(var(--padding) * 0.75);
    border-radius: calc(var(--padding) * 0.75);
}

.flight__confirmation__onlinecheckin__services__service__body__description {
    font-size: calc(var(--font-size-base) * 0.875);
    line-height: calc(var(--font-size-base) * 1.25);
    font-weight: 400;
    color: var(--disabled-color);
}

.flight__confirmation__onlinecheckin__services__service__trigger__button {
    white-space: nowrap;
    color: var(--text-default);
    border-color: var(--border-disabled-color);
    padding: calc(var(--padding) * 0.625) calc(var(--padding) * 1.5);
}
